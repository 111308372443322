import React, { useState, useEffect } from 'react';
import {
  ArrowLeft, ChevronDown, RefreshCw, TrendingUp, TrendingDown,
  Plus, Minus, Eye, EyeOff, Settings, Bell,
  Home, Rocket, LineChart, Timer
} from 'lucide-react';
import Chart from './chart';

const CryptoTrading = () => {
  const [selectedCoin, setSelectedCoin] = useState('DOGE');
  const [showCoinSelector, setShowCoinSelector] = useState(false);
  const [orderType, setOrderType] = useState('limit');
  const [tradeType, setTradeType] = useState('spot');
  const [price, setPrice] = useState('');
  const [amount, setAmount] = useState('');
  const [leverage, setLeverage] = useState(10);
  const [showBalance, setShowBalance] = useState(true);
  const [currentPrice, setCurrentPrice] = useState(0);
  const [priceChangePercent, setPriceChangePercent] = useState(0);
  const [isPriceUp, setIsPriceUp] = useState(true);
  const [openOrders, setOpenOrders] = useState([]);

  const memeCoins = [
    { symbol: 'DOGE', name: 'Dogecoin', price: 0.15234, change: 2.45 },
    { symbol: 'SHIB', name: 'Shiba Inu', price: 0.00002345, change: -1.23 },
    { symbol: 'PEPE', name: 'Pepe', price: 0.00000123, change: 8.90 },
    { symbol: 'FLOKI', name: 'Floki', price: 0.00000789, change: 5.67 },
    { symbol: 'BONK', name: 'Bonk', price: 0.00000456, change: -3.21 }
  ];

  const TradingForm = () => (
    <div className="space-y-4">
      {/* Trade Type Selector */}
      <div className="flex gap-2 bg-gray-800/50 p-1 rounded-lg">
        {['spot', 'margin', 'futures'].map((type) => (
          <button
            key={type}
            onClick={() => setTradeType(type)}
            className={`flex-1 py-1.5 rounded-lg text-xs font-medium transition-all ${
              tradeType === type
                ? 'bg-yellow-500 text-gray-900'
                : 'text-gray-400 hover:bg-gray-700'
            }`}
          >
            {type.charAt(0).toUpperCase() + type.slice(1)}
          </button>
        ))}
      </div>

      {/* Order Type */}
      <div className="flex gap-2">
        {['limit', 'market', 'stop'].map((type) => (
          <button
            key={type}
            onClick={() => setOrderType(type)}
            className={`px-3 py-1.5 rounded-lg text-xs ${
              orderType === type
                ? 'bg-gray-700 text-white'
                : 'text-gray-400 hover:bg-gray-800'
            }`}
          >
            {type.charAt(0).toUpperCase() + type.slice(1)}
          </button>
        ))}
      </div>

      {/* Price Input */}
      <div className="space-y-1">
        <label className="text-xs text-gray-400">Price</label>
        <div className="relative">
          <input
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            className="w-full bg-gray-800 rounded-lg px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-yellow-500/50"
            placeholder="0.00"
            disabled={orderType === 'market'}
          />
          <span className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 text-xs">
            USDT
          </span>
        </div>
      </div>

      {/* Amount Input */}
      <div className="space-y-1">
        <label className="text-xs text-gray-400">Amount</label>
        <div className="relative">
          <input
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            className="w-full bg-gray-800 rounded-lg px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-yellow-500/50"
            placeholder="0.00"
          />
          <span className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 text-xs">
            {selectedCoin}
          </span>
        </div>
      </div>

      {/* Percentage Selectors */}
      <div className="grid grid-cols-4 gap-2">
        {[25, 50, 75, 100].map((percent) => (
          <button
            key={percent}
            className="bg-gray-800 hover:bg-gray-700 rounded-lg py-1 text-xs text-gray-400 hover:text-yellow-500"
          >
            {percent}%
          </button>
        ))}
      </div>

      {/* Leverage Slider */}
      {tradeType !== 'spot' && (
        <div className="bg-gray-800/50 rounded-lg p-3">
          <div className="flex items-center justify-between mb-2">
            <span className="text-xs text-gray-400">Leverage</span>
            <span className="text-xs font-bold text-yellow-500">{leverage}x</span>
          </div>
          <div className="flex items-center gap-2">
            <button
              onClick={() => setLeverage(prev => Math.max(1, prev - 1))}
              className="p-1 bg-gray-700 rounded hover:bg-gray-600"
            >
              <Minus className="w-3 h-3" />
            </button>
            <input
              type="range"
              min="1"
              max="100"
              value={leverage}
              onChange={(e) => setLeverage(parseInt(e.target.value))}
              className="flex-1 accent-yellow-500"
            />
            <button
              onClick={() => setLeverage(prev => Math.min(100, prev + 1))}
              className="p-1 bg-gray-700 rounded hover:bg-gray-600"
            >
              <Plus className="w-3 h-3" />
            </button>
          </div>
        </div>
      )}

      {/* Buy/Sell Buttons */}
      <div className="grid grid-cols-2 gap-3">
        <button className="bg-green-500 hover:bg-green-600 text-white text-sm font-semibold py-2 rounded-lg transition-colors">
          Buy {selectedCoin}
        </button>
        <button className="bg-red-500 hover:bg-red-600 text-white text-sm font-semibold py-2 rounded-lg transition-colors">
          Sell {selectedCoin}
        </button>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-950 text-gray-100">
      {/* Header */}
      <header className="sticky top-0 z-30 border-b border-gray-800 bg-gray-900/95 backdrop-blur-sm">
        <div className="max-w-[1920px] mx-auto px-4 py-2">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-4">
              <button className="p-1.5 text-gray-400 hover:text-gray-200 hover:bg-gray-800 rounded-lg">
                <ArrowLeft className="w-4 h-4" />
              </button>

              <button
                onClick={() => setShowCoinSelector(!showCoinSelector)}
                className="flex items-center gap-2 bg-gray-800 hover:bg-gray-700 px-3 py-1.5 rounded-lg transition-colors"
              >
                <div className="w-6 h-6 bg-yellow-500/20 rounded-full flex items-center justify-center">
                  <Rocket className="w-4 h-4 text-yellow-500" />
                </div>
                <div className="hidden sm:block">
                  <div className="text-sm font-medium">{selectedCoin}/USDT</div>
                  <div className="text-xs text-gray-400">Meme Coins</div>
                </div>
                <ChevronDown className="w-3 h-3 text-gray-400" />
              </button>

              <div className="hidden sm:block">
                <div className="text-xs text-gray-400">24h Change</div>
                <div className="flex items-center gap-2">
                  <span className="text-base font-bold">{currentPrice.toFixed(6)}</span>
                  <span className={`text-xs ${priceChangePercent >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                    {priceChangePercent >= 0 ? '+' : ''}{priceChangePercent.toFixed(2)}%
                  </span>
                </div>
              </div>
            </div>

            <div className="flex items-center gap-2">
              <button className="p-1.5 text-gray-400 hover:text-yellow-500 hover:bg-gray-800 rounded-lg">
                <Bell className="w-4 h-4" />
              </button>
              <button
                onClick={() => setShowBalance(!showBalance)}
                className="p-1.5 text-gray-400 hover:text-yellow-500 hover:bg-gray-800 rounded-lg"
              >
                {showBalance ? <Eye className="w-4 h-4" /> : <EyeOff className="w-4 h-4" />}
              </button>
              <button className="p-1.5 text-gray-400 hover:text-yellow-500 hover:bg-gray-800 rounded-lg">
                <Settings className="w-4 h-4" />
              </button>
            </div>
          </div>
        </div>
      </header>

      {/* Main Content */}
      <div className="max-w-[1920px] mx-auto p-4">
        {/* Desktop Layout */}
        <div className="hidden lg:grid grid-cols-4 gap-4 h-[calc(100vh-5rem)]">
          {/* Chart Section - Expanded */}
          <div className="col-span-3 bg-gray-900/50 rounded-lg border border-gray-800 overflow-hidden">
            <div className="h-full p-4">
              <Chart />
            </div>
          </div>

          {/* Trading Form Section */}
          <div className="col-span-1 bg-gray-900/50 rounded-lg border border-gray-800 overflow-hidden">
            <div className="p-4 h-full overflow-y-auto scrollbar-thin scrollbar-thumb-gray-800">
              <TradingForm />
            </div>
          </div>
        </div>

        {/* Mobile Layout */}
        <div className="lg:hidden space-y-4">
          {/* Chart */}
          <div className="bg-gray-900/50 rounded-lg p-4 border border-gray-800">
            <div className="h-[300px] sm:h-[400px]">
              <Chart />
            </div>
          </div>

          {/* Trading Controls */}
          <div className="bg-gray-900/50 rounded-lg p-4 border border-gray-800">
            <TradingForm />
          </div>
        </div>

        {/* Coin Selector Modal */}
        {showCoinSelector && (
          <div className="fixed inset-0 bg-gray-950/90 backdrop-blur-sm z-40">
            <div className="bg-gray-900 max-w-md mx-auto mt-20 rounded-lg">
              <div className="p-4 border-b border-gray-800">
                <div className="flex items-center justify-between">
                  <h3 className="text-sm font-semibold">Select Coin</h3>
                  <button
                    onClick={() => setShowCoinSelector(false)}
                    className="text-gray-400 hover:text-gray-200"
                  >
                    ✕
                  </button>
                </div>
              </div>

              <div className="p-2">
                <div className="p-2">
                  <input
                    type="text"
                    placeholder="Search coins..."
                    className="w-full bg-gray-800 rounded-lg px-3 py-2 text-xs focus:outline-none focus:ring-2 focus:ring-yellow-500/50 text-gray-200"
                  />
                </div>

                <div className="max-h-[60vh] overflow-y-auto">
                  {memeCoins.map((coin) => (
                    <button
                      key={coin.symbol}
                      onClick={() => {
                        setSelectedCoin(coin.symbol);
                        setShowCoinSelector(false);
                      }}
                      className="w-full flex items-center justify-between p-3 hover:bg-gray-800 rounded-lg"
                    >
                      <div className="flex items-center gap-3">
                        <div className="w-6 h-6 bg-yellow-500/20 rounded-full flex items-center justify-center">
                          <span className="text-yellow-500 text-xs font-semibold">{coin.symbol[0]}</span>
                        </div>
                        <div>
                          <div className="text-sm font-medium text-left">{coin.symbol}/USDT</div>
                          <div className="text-xs text-gray-400">{coin.name}</div>
                        </div>
                      </div>
                      <div className={`text-xs ${coin.change >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                        {coin.change >= 0 ? '+' : ''}{coin.change}%
                      </div>
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CryptoTrading;