import React, { useState, useEffect, useRef, useMemo } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer
} from 'recharts';

const Chart = () => {
  const [priceData, setPriceData] = useState([]);
  const bufferRef = useRef([]);
  const wsRef = useRef(null);
  const animationFrameRef = useRef();
  const lastUpdateRef = useRef(Date.now());

  // Initialize with historical data
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const response = await fetch(
          `https://api.binance.com/api/v3/klines?symbol=DOGEUSDT&interval=1m&limit=200`
        );
        const data = await response.json();
        
        const initialData = data.map(candle => ({
          timestamp: candle[0],
          price: parseFloat(candle[4]),
          volume: parseFloat(candle[5])
        }));

        setPriceData(initialData);
        bufferRef.current = [initialData[initialData.length - 1]];
      } catch (error) {
        console.error('Error fetching historical data:', error);
      }
    };

    fetchInitialData();

    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, []);

  // WebSocket connection and data buffering
  useEffect(() => {
    const connectWebSocket = () => {
      wsRef.current = new WebSocket('wss://stream.binance.com:9443/ws/dogeusdt@trade');

      wsRef.current.onmessage = (event) => {
        const data = JSON.parse(event.data);
        const newPrice = {
          timestamp: data.T,
          price: parseFloat(data.p),
          volume: parseFloat(data.q)
        };

        bufferRef.current.push(newPrice);
      };

      wsRef.current.onerror = (error) => {
        console.error('WebSocket error:', error);
      };

      wsRef.current.onclose = () => {
        setTimeout(connectWebSocket, 3000);
      };
    };

    connectWebSocket();

    // Smooth update function using requestAnimationFrame
    const updateChart = () => {
      const now = Date.now();
      const timeSinceLastUpdate = now - lastUpdateRef.current;
      
      // Update every 1000ms (1 second)
      if (timeSinceLastUpdate >= 1000 && bufferRef.current.length > 0) {
        lastUpdateRef.current = now;
        
        // Calculate the average price from buffer
        const averagePrice = bufferRef.current.reduce((acc, curr) => acc + curr.price, 0) / bufferRef.current.length;
        
        setPriceData(prevData => {
          const newData = [...prevData, {
            timestamp: now,
            price: averagePrice,
            volume: bufferRef.current.reduce((acc, curr) => acc + curr.volume, 0)
          }].slice(-200); // Keep last 200 points
          
          return newData;
        });
        
        // Clear buffer
        bufferRef.current = [];
      }
      
      animationFrameRef.current = requestAnimationFrame(updateChart);
    };

    updateChart();

    return () => {
      if (wsRef.current) {
        wsRef.current.close();
      }
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, []);

  // Memoize the domain calculation
  const yDomain = useMemo(() => {
    if (priceData.length === 0) return ['auto', 'auto'];
    const prices = priceData.map(d => d.price);
    const min = Math.min(...prices);
    const max = Math.max(...prices);
    const padding = (max - min) * 0.1;
    return [min - padding, max + padding];
  }, [priceData]);

  return (
    <div className="h-[calc(100%-3rem)]">
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={priceData}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <XAxis
            dataKey="timestamp"
            tick={{ fill: '#6B7280' }}
            tickFormatter={(time) => new Date(time).toLocaleTimeString()}
            interval="preserveStartEnd"
            minTickGap={50}
            domain={['auto', 'auto']}
          />
          <YAxis
            tick={{ fill: '#6B7280' }}
            orientation="right"
            domain={yDomain}
            tickFormatter={(value) => value.toFixed(6)}
            width={80}
          />
          <Tooltip
            contentStyle={{
              backgroundColor: '#1F2937',
              border: 'none',
              borderRadius: '8px'
            }}
            labelFormatter={(time) => new Date(time).toLocaleString()}
            formatter={(value) => [value.toFixed(6), 'Price']}
            isAnimationActive={false}
          />
          <Line
            type="linear"
            dataKey="price"
            stroke="#EAB308"
            dot={false}
            strokeWidth={1.5}
            isAnimationActive={false}
            connectNulls
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Chart;