import React, { useState, useEffect } from 'react';
import { Bell, Search, Headphones, Gift, Wallet, Calendar, RefreshCw, MoreHorizontal, Eye, EyeOff, TrendingUp, TrendingDown, Home, CreditCard, X, DollarSign, Sparkles,  Mail, Star, Flame, 
  Globe,
  BadgeDollarSign,
  GanttChart,
  BookOpen,
  Shield,
  ExternalLink,
  Cpu,
  Gem  } from 'lucide-react';
import Header from './header';
import { ref, onValue } from 'firebase/database';
import { database, auth } from './firebase';
import { useNavigate } from 'react-router-dom';

const ExchangeWebsite = () => {
  const [showBalance, setShowBalance] = useState(true);
  const [marketData, setMarketData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPairIndex, setCurrentPairIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [balance, setBalance] = useState(0);
  const [showPromoModal, setShowPromoModal] = useState(true);
  const [showCardAlert, setShowCardAlert] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const currentYear = new Date().getFullYear();
  const [activeCategory, setActiveCategory] = useState('hot');
  const navigate = useNavigate();
  const processMarketData = () => {
    if (!marketData.length) return {};
    
    const gainers = [...marketData].sort((a, b) => b.price_change_percentage_24h - a.price_change_percentage_24h).slice(0, 5);
    const losers = [...marketData].sort((a, b) => a.price_change_percentage_24h - b.price_change_percentage_24h).slice(0, 5);
    const hot = [...marketData].sort((a, b) => b.total_volume - a.total_volume).slice(0, 5);
    const new_listings = [...marketData].sort((a, b) => b.current_price - a.current_price).slice(0, 5); // Simulating new listings
    
    return {
      favorites: marketData.slice(0, 5), // In a real app, this would be user-specific
      hot,
      new: new_listings,
      gainers,
      losers
    };
  };

  const categories = [
    { id: 'favorites', label: 'Favorites', icon: Star },
    { id: 'hot', label: 'Hot', icon: Flame },
    { id: 'new', label: 'New', icon: Sparkles },
    { id: 'gainers', label: 'Top Gainers', icon: TrendingUp },
    { id: 'losers', label: 'Top Losers', icon: TrendingDown }
  ];
  const getHotPairs = (data) => {
    if (!data.length) return [];
    return data
      .filter(coin => Math.abs(coin.price_change_percentage_24h) > 2 || coin.total_volume > 100000000)
      .map(coin => ({
        symbol: `${coin.symbol.toUpperCase()}/USDT`,
        change: coin.price_change_percentage_24h
      }));
  };
  const PromoModal = () => (
    <div 
      className={`fixed inset-0 flex items-center justify-center z-50 p-4 transition-opacity duration-300 ${
        !showPromoModal ? 'opacity-0 pointer-events-none' : 'opacity-100'
      }`}
    >
      <div 
        className="fixed inset-0 bg-black bg-opacity-50 transition-opacity duration-300"
        onClick={handleCloseModal}
      />
      <div 
        className={`bg-gray-900 rounded-lg p-4 w-80 relative transform transition-transform duration-300 ${
          isClosing || !showPromoModal ? 'scale-0' : 'scale-100'
        }`}
      >
        <button
          onClick={handleCloseModal}
          className="absolute top-2 right-2 text-gray-400 hover:text-white transition-colors duration-200"
        >
          <X className="w-4 h-4" />
        </button>
        
        <div className="flex items-center justify-center mb-3">
          <div className="bg-yellow-600 rounded-full p-2">
            <DollarSign className="w-6 h-6 text-black" />
          </div>
        </div>
        
        <h2 className="text-lg font-bold text-center mb-2">
          Get $25 Bonus! 🎉
        </h2>
        
        <p className="text-gray-300 text-center text-sm mb-4">
          Deposit $100+ or trade P2P for instant $25
        </p>
        
        <div className="space-y-2">
          <button
            onClick={() => {
              handleCloseModal();
              setTimeout(() => navigate('/deposit'), 300);
            }}
            className="w-full bg-yellow-600 text-black font-semibold py-2 text-sm rounded-lg hover:bg-yellow-500 transition-colors flex items-center justify-center gap-2"
          >
            <Wallet className="w-4 h-4" />
            Deposit Now
          </button>
          
          <button
            onClick={() => {
              handleCloseModal();
              setTimeout(() => navigate('/p2p'), 300);
            }}
            className="w-full bg-gray-800 text-white font-semibold py-2 text-sm rounded-lg hover:bg-gray-700 transition-colors flex items-center justify-center gap-2"
          >
            <RefreshCw className="w-4 h-4" />
            Trade P2P
          </button>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        // Fetch market data
        const response = await fetch(
          'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=20&page=1&sparkline=false'
        );
        const data = await response.json();
        setMarketData(data);

        // Set up Firebase balance listener
        const user = auth.currentUser;
        if (user) {
          const balanceRef = ref(database, `users/${user.uid}/balance`);
          onValue(balanceRef, (snapshot) => {
            if (snapshot.exists()) {
              setBalance(snapshot.val() || 0);
            }
          });
        }

        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    };

    fetchData();
    const interval = setInterval(fetchData, 30000); // Update every 30 seconds

    // Cleanup function
    return () => {
      clearInterval(interval);
      // Firebase listener will be automatically cleaned up when component unmounts
    };
  }, []);

  // Hot pairs animation effect
  useEffect(() => {
    if (!marketData.length) return;

    const hotPairs = getHotPairs(marketData);
    if (!hotPairs.length) return;

    const interval = setInterval(() => {
      setIsTransitioning(true);
      setTimeout(() => {
        setCurrentPairIndex((prevIndex) => (prevIndex + 1) % hotPairs.length);
        setIsTransitioning(false);
      }, 300);
    }, 2000);

    return () => clearInterval(interval);
  }, [marketData]);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPromoModal(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);
  const handleCloseModal = () => {
    setIsClosing(true);
    setTimeout(() => {
      setShowPromoModal(false);
      setIsClosing(false);
    }, 300);
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(amount);
  };

  const fetchMarketData = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(
        'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=20&page=1&sparkline=false'
      );
      const data = await response.json();
      setMarketData(data);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching market data:', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchMarketData();
    const interval = setInterval(fetchMarketData, 30000); // Update every 30 seconds
    return () => clearInterval(interval);
  }, []);

  const formatPrice = (price) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 6
    }).format(price);
  };

  const formatPercentage = (num) => {
    return `${num?.toFixed(2)}%`;
  };

  // Market Card for Mobile View
  const MarketCard = ({ coin }) => (
    <div className="bg-gray-900 rounded-lg p-4 space-y-3">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-3">
          <img src={coin.image} alt={coin.name} className="w-8 h-8" />
          <div>
            <h3 className="font-semibold">{coin.symbol.toUpperCase()}</h3>
            <p className="text-gray-400 text-sm">{coin.name}</p>
          </div>
        </div>
        <div className={`flex items-center ${coin.price_change_percentage_24h >= 0 ? 'text-green-500' : 'text-red-500'}`}>
          {coin.price_change_percentage_24h >= 0 ?
            <TrendingUp className="w-4 h-4 mr-1" /> :
            <TrendingDown className="w-4 h-4 mr-1" />
          }
          {formatPercentage(coin.price_change_percentage_24h)}
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <p className="text-gray-400 text-sm">Price</p>
          <p className="font-semibold">{formatPrice(coin.current_price)}</p>
        </div>
        <div>
          <p className="text-gray-400 text-sm">24h Volume</p>
          <p className="font-semibold">{formatPrice(coin.total_volume)}</p>
        </div>
      </div>
    </div>
  );
  const CardAlert = () => (
    showCardAlert && (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
        <div className="bg-gray-900 rounded-lg p-6 max-w-sm w-full relative animate-fade-in">
          <button
            onClick={() => setShowCardAlert(false)}
            className="absolute top-4 right-4 text-gray-400 hover:text-white"
          >
            <X className="w-5 h-5" />
          </button>
          
          <div className="flex items-center justify-center mb-4">
            <CreditCard className="w-12 h-12 text-yellow-600" />
          </div>
          
          <h2 className="text-xl font-bold text-center mb-4">
            Coming Soon!
          </h2>
          
          <p className="text-gray-300 text-center mb-6">
            Nexia Card will be available shortly. Stay tuned for updates!
          </p>
          
          <button
            onClick={() => setShowCardAlert(false)}
            className="w-full bg-gray-800 text-white font-semibold py-3 rounded-lg hover:bg-gray-700 transition-colors"
          >
            Got it
          </button>
        </div>
      </div>
    )
  );

  return (
    <div className="min-h-screen bg-black text-white text-sm">
      <PromoModal />
      <CardAlert />
      {/* Header */}
      <Header />

      {/* Search Bar */}
      <div className="px-4 py-2 mt-8 lg:mt-14">
        <div className="bg-gray-900 rounded-lg flex items-center p-2">
          <Search className="w-4 h-4 mr-2" />
          <div className={`flex items-center transition-opacity duration-500 ${isTransitioning ? 'opacity-0' : 'opacity-100'}`}>
            <span className="text-yellow-600 mr-1 text-xs">🔥</span>
            {marketData.length > 0 ? (
              <>
                <span className="text-gray-400 text-xs">
                  {getHotPairs(marketData)[currentPairIndex]?.symbol || 'SUPRA/USDT'}
                </span>
                <span className={`ml-2 text-xs ${getHotPairs(marketData)[currentPairIndex]?.change >= 0 ? 'text-green-500' : 'text-red-500'
                  }`}>
                  {getHotPairs(marketData)[currentPairIndex]?.change >= 0 ? '+' : ''}
                  {getHotPairs(marketData)[currentPairIndex]?.change?.toFixed(1)}%
                </span>
              </>
            ) : (
              <span className="text-gray-400 text-xs">SUPRA/USDT</span>
            )}
          </div>
        </div>
      </div>

      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* Main Content Area */}
          <div className="lg:col-span-2 space-y-6 order-2 lg:order-1">
           {/* User Balance Section */}
           <div className="hidden lg:block bg-gray-900 rounded-lg p-6">
  <div className="flex items-center gap-3 mb-4">
    <h2 className="text-lg font-semibold">Total Balance</h2>
    <button
      onClick={() => setShowBalance(!showBalance)}
      className="text-gray-400 hover:text-white"
    >
      {showBalance ? <EyeOff className="w-5 h-5" /> : <Eye className="w-5 h-5" />}
    </button>
  </div>
  <div className="space-y-4">
    <div>
      <p className="text-gray-400 text-sm">Balance (USD)</p>
      <p className="text-2xl font-bold mb-2">
        {showBalance ? formatCurrency(balance) : '••••••••'}
      </p>
      <div className="flex items-center">
        <p className="text-gray-400 text-sm mr-2">≈ BTC</p>
        <p className="font-medium">
          {showBalance ? (
            marketData.length > 0 ? (
              `${(balance / marketData.find(coin => coin.id === 'bitcoin')?.current_price).toFixed(8)} BTC`
            ) : (
              'Loading...'
            )
          ) : (
            '••••••••'
          )}
        </p>
      </div>
    </div>
  </div>
</div>

            {/* Market Overview Section */}
           <div className="space-y-4">
              <div className="flex justify-between items-center">
                <h2 className="text-xl font-bold">Market Overview</h2>
                <button
                  onClick={fetchMarketData}
                  className="flex items-center space-x-2 text-gray-400 hover:text-white"
                >
                  <RefreshCw className="w-4 h-4" />
                  <span className="text-sm">Refresh</span>
                </button>
              </div>

              {/* Category Tabs */}
              <div className="flex space-x-4 overflow-x-auto pb-2">
                {categories.map(({ id, label, icon: Icon }) => (
                  <button
                    key={id}
                    onClick={() => setActiveCategory(id)}
                    className={`flex items-center space-x-2 px-4 py-2 rounded-lg whitespace-nowrap ${
                      activeCategory === id
                        ? 'bg-yellow-600 text-black font-semibold'
                        : 'bg-gray-900 text-gray-400 hover:bg-gray-800'
                    }`}
                  >
                    <Icon className="w-4 h-4" />
                    <span>{label}</span>
                  </button>
                ))}
              </div>

              {isLoading ? (
                <div className="text-center py-12">
                  <p className="text-gray-400">Loading market data...</p>
                </div>
              ) : (
                <>
                  {/* Desktop View - Table */}
                  <div className="hidden lg:block overflow-x-auto">
                    <table className="w-full">
                      <thead>
                        <tr className="text-gray-400 border-b border-gray-800">
                          <th className="pb-4 text-left">Asset</th>
                          <th className="pb-4 text-right">Price</th>
                          <th className="pb-4 text-right">24h Change</th>
                          <th className="pb-4 text-right">24h Volume</th>
                          <th className="pb-4 text-right">Market Cap</th>
                        </tr>
                      </thead>
                      <tbody>
                        {processMarketData()[activeCategory]?.map((coin) => (
                          <tr key={coin.id} className="border-b border-gray-800 hover:bg-gray-900">
                            <td className="py-4">
                              <div className="flex items-center space-x-3">
                                <img src={coin.image} alt={coin.name} className="w-6 h-6" />
                                <div>
                                  <p className="font-semibold">{coin.symbol.toUpperCase()}</p>
                                  <p className="text-gray-400 text-sm">{coin.name}</p>
                                </div>
                              </div>
                            </td>
                            <td className="py-4 text-right">{formatPrice(coin.current_price)}</td>
                            <td className={`py-4 text-right ${
                              coin.price_change_percentage_24h >= 0 ? 'text-green-500' : 'text-red-500'
                            }`}>
                              {formatPercentage(coin.price_change_percentage_24h)}
                            </td>
                            <td className="py-4 text-right">{formatPrice(coin.total_volume)}</td>
                            <td className="py-4 text-right">{formatPrice(coin.market_cap)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  {/* Mobile View - Cards */}
                  <div className="lg:hidden space-y-4">
                    {processMarketData()[activeCategory]?.map((coin) => (
                      <div key={coin.id} className="bg-gray-900 rounded-lg p-4 space-y-3">
                        <div className="flex items-center justify-between">
                          <div className="flex items-center space-x-3">
                            <img src={coin.image} alt={coin.name} className="w-8 h-8" />
                            <div>
                              <h3 className="font-semibold">{coin.symbol.toUpperCase()}</h3>
                              <p className="text-gray-400 text-sm">{coin.name}</p>
                            </div>
                          </div>
                          <div className={`flex items-center ${
                            coin.price_change_percentage_24h >= 0 ? 'text-green-500' : 'text-red-500'
                          }`}>
                            {coin.price_change_percentage_24h >= 0 ? (
                              <TrendingUp className="w-4 h-4 mr-1" />
                            ) : (
                              <TrendingDown className="w-4 h-4 mr-1" />
                            )}
                            {formatPercentage(coin.price_change_percentage_24h)}
                          </div>
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                          <div>
                            <p className="text-gray-400 text-sm">Price</p>
                            <p className="font-semibold">{formatPrice(coin.current_price)}</p>
                          </div>
                          <div>
                            <p className="text-gray-400 text-sm">24h Volume</p>
                            <p className="font-semibold">{formatPrice(coin.total_volume)}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          



          </div>

          {/* Right Column */}
          <div className="space-y-6 order-1 lg:order-2">
            {/* Rewards Banner */}
            <div className="bg-gray-900 rounded-lg p-6">
              <div className="flex justify-between items-center">
                <span className="text-gray-400">Explore Markets</span>
                <span className="text-gray-400"></span>
              </div>
              <h2 className="text-xl font-bold my-3">Deposit Now to Start Trading!</h2>
              <p className="text-gray-400 mb-6">Over 300 payment methods and verified P2P traders worldwide!</p>
              <button
                onClick={() => navigate('/deposit')}
                className="w-full bg-yellow-600 text-black font-semibold py-3 rounded-lg hover:bg-yellow-500 transition-colors"
              >
                Deposit/Buy with card/P2P
              </button>
            </div>

            {/* Feature Grid */}
            <div className="grid grid-cols-2 gap-4">
  <div 
    onClick={() => navigate('/deposit')}
    className="bg-gray-900 rounded-lg p-4 flex items-center space-x-3 cursor-pointer hover:bg-gray-800 transition-colors"
  >
    <div className="w-10 h-10 bg-gray-800 rounded-lg flex items-center justify-center">
      <Wallet className="w-5 h-5" />
    </div>
    <span>Deposit</span>
  </div>
  
  <div 
    onClick={() => navigate('/p2p')}
    className="bg-gray-900 rounded-lg p-4 flex items-center space-x-3 cursor-pointer hover:bg-gray-800 transition-colors"
  >
    <div className="w-10 h-10 bg-gray-800 rounded-lg flex items-center justify-center">
      <RefreshCw className="w-5 h-5" />
    </div>
    <span>P2P Trading</span>
  </div>
  
  <div 
          onClick={() => setShowCardAlert(true)}
          className="bg-gray-900 rounded-lg p-4 flex items-center space-x-3 cursor-pointer hover:bg-gray-800 transition-colors"
        >
          <div className="w-10 h-10 bg-gray-800 rounded-lg flex items-center justify-center relative">
            <CreditCard className="w-5 h-5" />
            <span className="absolute -top-2 -right-2 text-[8px] bg-red-500 px-1.5 py-0.5 rounded text-white font-medium">NEW</span>
          </div>
          <span>Nexia Card</span>
        </div>
  
  <div 
    onClick={() => navigate('/assets')}
    className="bg-gray-900 rounded-lg p-4 flex items-center space-x-3 cursor-pointer hover:bg-gray-800 transition-colors"
  >
    <div className="w-10 h-10 bg-gray-800 rounded-lg flex items-center justify-center">
      <Gift className="w-5 h-5" />
    </div>
    <span>Assets</span>
  </div>
</div>


<div className="relative overflow-hidden rounded-lg bg-gradient-to-r from-gray-900 via-yellow-900/10 to-gray-900 p-0.5">
  <div className="relative rounded-[7px] bg-gradient-to-r from-gray-900 to-black p-4">
    {/* Animated gradient overlay */}
    <div className="absolute inset-0 bg-gradient-to-r from-yellow-600/10 via-yellow-600/5 to-transparent animate-pulse" />
    
    <div className="relative flex items-center justify-between">
      <div className="flex items-center space-x-4">
        <div className="relative">
          <div className="absolute -inset-1 bg-yellow-600 rounded-lg blur-sm opacity-20 animate-pulse" />
          <div className="relative bg-gradient-to-tr from-yellow-600 to-yellow-500 rounded-lg p-2">
            <Gift className="w-5 h-5 text-black" />
          </div>
        </div>
        
        <div className="space-y-1">
          <div className="flex items-center gap-2">
            <h3 className="font-bold text-base">Welcome Bonus</h3>
            <span className="bg-yellow-600/20 text-yellow-500 text-xs px-2 py-0.5 rounded-full font-medium flex items-center gap-1">
              <Sparkles className="w-3 h-3" />
              $25
            </span>
          </div>
          <p className="text-sm text-gray-400">Deposit BTC or Trade $100+ via P2P</p>
        </div>
      </div>

      <button 
        className="relative group"
        onClick={() => navigate('/deposit')}
      >
        <div className="absolute -inset-1 bg-gradient-to-r from-yellow-600 to-yellow-500 rounded-lg blur opacity-25 group-hover:opacity-40 transition duration-200" />
        <div className="relative px-4 py-2 bg-black rounded-lg font-semibold text-sm text-yellow-500 group-hover:text-yellow-400 transition duration-200 flex items-center gap-2">
          Deposit
          <div className="w-4 h-4 rounded-full bg-yellow-600/20 flex items-center justify-center">
            <span className="text-xs">→</span>
          </div>
        </div>
      </button>
    </div>
  </div>
</div>

            {/* Hot Markets Section */}
            <div className="bg-gray-900 rounded-lg p-4">
              <div className="flex justify-between items-center mb-4">
                <h3 className="font-semibold">Hot Markets</h3>
                <button className="text-gray-400 hover:text-white text-sm">View All</button>
              </div>
              <div className="space-y-4">
                {marketData.slice(0, 5).map((coin) => (
                  <div key={coin.id} className="flex items-center justify-between">
                    <div className="flex items-center space-x-3">
                      <div className="w-8 h-8 bg-gray-800 rounded-full flex items-center justify-center overflow-hidden">
                        <img src={coin.image} alt={coin.name} className="w-6 h-6" />
                      </div>
                      <div>
                        <p className="font-medium">{coin.symbol.toUpperCase()}/USDT</p>
                        <p className="text-gray-400 text-sm">{coin.name}</p>
                      </div>
                    </div>
                    <div className="text-right">
                      <p className="font-medium">{formatPrice(coin.current_price)}</p>
                      <p className={`text-sm ${coin.price_change_percentage_24h >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                        {coin.price_change_percentage_24h >= 0 ? '+' : ''}
                        {formatPercentage(coin.price_change_percentage_24h)}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </main>

     
    </div>
  );
};

export default ExchangeWebsite;