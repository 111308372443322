import React from 'react';
import { Routes, Route, Navigate, useLocation  } from 'react-router-dom';
import LoginPage from './login';
import Header from './header';
import MyWallet from './wallet';
import CryptoDashboard from './homepage';
import CryptoLanding from './landingpage';
import OrdersAndLoans from './ordersloans';
import DepositPage from './deposit';
import EarnCryptoDashboard from './earncrypto';
import CopyTradingWebsite from './copytrading';
import WithdrawPage from './withdraw';
import HelpCenter from './helpcenter'
import MarketDashboard from './marketoverview'
import NotificationSystem from './notifications'
import SignupPage from './signup.js'
import CryptoSettings from './settings'
import CryptoBlog from './blog'
import CryptoConverter from './convert'
import CryptoNews from './marketnews'
import CryptoForum from './community'
import Comment from './Comment'
import CryptoEducation from './education'
import CryptoOrders from './orders'
import TransactionsPage from './transactions'
import CryptoEvents from './events'
import EmailVerificationHandler from './verify';
import AdminDashboard from './admin';
import AdminLogin from './adminlogin';
import P2PTrading from './p2p';
import CreateOfferSteps from './createoffers';
import TradeDetails from './tradedetails';  
import TradeProcess from './tradeprocess';  
import PaymentMethodBadge from './paymentmethod';  
import TradeChat from './chat';  
import ChatList from './mychats';  
import MarketTrends from './markettrends';  
import TradingPairs from './tradingpairs';  
import TradingChart from './tradingchart';  
import CryptoDeposit from './carddeposit';  
import AssetsWebsite from './assets';  
import P2POffersList from './offerslist';  
import NexiaInvestmentPortal from './invest';  
import MemeCoins from './memecoins';  
import Chart from './chart';  



import { auth } from './firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Coins } from 'lucide-react';
const LoadingScreen: React.FC = () => {
  return (
    <div className="min-h-screen bg-gray-900 flex flex-col items-center justify-center">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 80" className="w-full max-w-md">
        <text
          x="50%"
          y="50%"
          fontFamily="'Industry', 'Chakra Petch', sans-serif"
          fontSize="46"
          fontWeight="700"
          letterSpacing="-2"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          <animate
            attributeName="opacity"
            values="1;0.3;1"
            dur="2s"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="0.4 0 0.2 1; 0.4 0 0.2 1"
          />
          <tspan fill="white">nex</tspan>
          <tspan fill="#FFD700">i</tspan>
          <tspan fill="white">acoin.com</tspan>
        </text>
      </svg>

      {/* Loading Message */}
      <p className="mt-4 text-gray-400 text-sm animate-pulse">
        Please wait while we secure your connection
      </p>
    </div>
  )
};
const ProtectedRoute = ({ children }) => {
  const [user, loading] = useAuthState(auth);

  if (loading) {
    return <LoadingScreen />; 
  }

  if (!user) {
    return <Navigate to="/" />;
  }

  return children;
};
const App = () => {
  const location = useLocation();

  // Check for email verification parameters
  const queryParams = new URLSearchParams(location.search);
  const mode = queryParams.get('mode');
  const oobCode = queryParams.get('oobCode');

  // If this is a verification link, show the verification handler
  if (mode === 'verifyEmail' && oobCode) {
    return <EmailVerificationHandler oobCode={oobCode} />;
  }
  console.log("App component rendering"); // Debug log

  return (
    <div style={{ height: '100vh', backgroundColor: 'white' }}>
      <Routes>
        <Route path="/" element={<CryptoLanding/>} />
        <Route
          path="/homepage"
          element={
            <ProtectedRoute>
              <CryptoDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/ordersloans"
          element={
            <ProtectedRoute>
              <OrdersAndLoans />
            </ProtectedRoute>
          }
        />
        <Route
          path="/deposit"
          element={
            <ProtectedRoute>
              <DepositPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/earncrypto"
          element={
            <ProtectedRoute>
              <EarnCryptoDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/copytrading"
          element={
            <ProtectedRoute>
              <CopyTradingWebsite />
            </ProtectedRoute>
          }
        />
        <Route
          path="/wallet"
          element={
            <ProtectedRoute>
              <MyWallet />
            </ProtectedRoute>
          }
        />
        <Route
          path="/withdraw"
          element={
            <ProtectedRoute>
              <WithdrawPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/helpcenter"
          element={
            <ProtectedRoute>
              <HelpCenter />
            </ProtectedRoute>
          }
        />
        <Route
          path="/marketoverview"
          element={
            <ProtectedRoute>
              <MarketDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/notifications"
          element={
            <ProtectedRoute>
              <NotificationSystem />
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <ProtectedRoute>
              <CryptoSettings />
            </ProtectedRoute>
          }
        />

        
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/ordersloans" element={<OrdersAndLoans />} />
        <Route path="/deposit" element={<DepositPage />} />
        <Route path="/earncrypto" element={<EarnCryptoDashboard />} />
        <Route path="/copytrading" element={<CopyTradingWebsite />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/header" element={<Header />} />
        <Route path="/wallet" element={<MyWallet />} />
        <Route path="/withdraw" element={<WithdrawPage />} />
        <Route path="/helpcenter" element={<HelpCenter />} />
        <Route path="/marketoverview" element={<MarketDashboard />} />
        <Route path="/notifications" element={<NotificationSystem />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/settings" element={<CryptoSettings />} />
        <Route path="/blog" element={<CryptoBlog />} />
        <Route path="/convert" element={<CryptoConverter />} />
        <Route path="/marketnews" element={<CryptoNews />} />
        <Route path="/community" element={<CryptoForum />} />
        <Route path="/Comment" element={<Comment />} />
        <Route path="/education" element={<CryptoEducation />} />
        <Route path="/orders" element={<CryptoOrders />} />
        <Route path="/transactions" element={<TransactionsPage />} />
        <Route path="/events" element={<CryptoEvents />} />
        <Route path="/admin" element={<AdminDashboard />} />
        <Route path="/adminlogin" element={<AdminLogin />} />
        <Route path="/p2p" element={<P2PTrading />} />
        <Route path="/createoffers" element={<CreateOfferSteps />} />
        <Route path="/tradedetails" element={<TradeDetails />} />
        <Route path="/trade/:offerId" element={<TradeDetails />} />
        <Route path="/trade/process/:tradeId" element={<TradeProcess />} />
        <Route path="/paymentmethod" element={<PaymentMethodBadge />} />
        <Route path="/chat" element={<TradeChat />} />
        <Route path="/mychats" element={<ChatList />} />
        <Route path="/markettrends" element={<MarketTrends />} />
        <Route path="/tradingpairs" element={<TradingPairs />} />
        <Route path="/tradingchart" element={<TradingChart />} />
        <Route path="/carddeposit" element={<CryptoDeposit />} />
        <Route path="/assets" element={<AssetsWebsite />} />
        <Route path="/offerslist" element={<P2POffersList />} />
        <Route path="/invest" element={<NexiaInvestmentPortal />} />
        <Route path="/memecoins" element={<MemeCoins />} />
        <Route path="/chart" element={<Chart />} />



      </Routes>
    </div>
  );

  
};



export default App;